import {
    GET_FAVOURITES,
    GET_FAVOURITES_SUCCESS,
    GET_FAVOURITES_ERROR,
    TOGGLE_FAVOURITES,
    TOGGLE_FAVOURITES_SUCCESS,
    TOGGLE_FAVOURITES_ERROR,
    LOGIN_USER_REQUIRE_SIGNATURE,
    GET_COIN_IMAGES,
    GET_COIN_IMAGES_SUCCESS,
    GET_COIN_IMAGES_ERROR,
    GET_PLAN_PRICES,
    GET_PLAN_PRICES_SUCCESS,
    GET_PLAN_PRICES_ERROR,
    GET_PLAN_PRICES_TIS,
    GET_PLAN_PRICES_TIS_SUCCESS,
    GET_PLAN_PRICES_TIS_ERROR,
    DISCORD_GET_TOKEN,
    DISCORD_GET_TOKEN_SUCCESS,
    DISCORD_GET_TOKEN_ERROR,
    DISCORD_GET_INVITE,
    DISCORD_GET_INVITE_SUCCESS,
    DISCORD_GET_INVITE_ERROR,
    LOGIN_USER,
    LOGIN_USER_REQUIRE_TWOFACTOR,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    TWO_FACTOR_GENERATE,
    TWO_FACTOR_GENERATE_SUCCESS,
    TWO_FACTOR_GENERATE_ERROR,
    TWO_FACTOR_ENABLE,
    TWO_FACTOR_ENABLE_SUCCESS,
    TWO_FACTOR_ENABLE_ERROR,
    TWO_FACTOR_DISABLE,
    TWO_FACTOR_DISABLE_SUCCESS,
    TWO_FACTOR_DISABLE_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    VERIFY_USER_EMAIL,
    VERIFY_USER_EMAIL_SUCCESS,
    VERIFY_USER_EMAIL_ERROR,
    REFRESH_PROFILE,
    REFRESH_PROFILE_SUCCESS,
    REFRESH_PROFILE_ERROR,
    CLEAR_MESSAGES,
    SET_CLOCKS,
    SET_ALERTS,
    SET_LINKS,
    SET_CHART,
    LOGIN_USER_METAMASK,
    LOGIN_USER_METAMASK_SUCCESS,
    LOGIN_USER_METAMASK_ERROR,
    METAMASK_GENERATE,
    METAMASK_GENERATE_SUCCESS,
    METAMASK_GENERATE_ERROR,
    METAMASK_ENABLE,
    METAMASK_ENABLE_SUCCESS,
    METAMASK_ENABLE_ERROR,
    METAMASK_DISABLE,
    METAMASK_DISABLE_SUCCESS,
    METAMASK_DISABLE_ERROR,
    REQUEST_PAYMENT_ADDRESS,
    REQUEST_PAYMENT_ADDRESS_SUCCESS,
    REQUEST_PAYMENT_ADDRESS_ERROR,
    REQUEST_PAYMENT_ADDRESS_TIS,
    REQUEST_PAYMENT_ADDRESS_TIS_SUCCESS,
    REQUEST_PAYMENT_ADDRESS_TIS_ERROR,
    CLEAR_PAYMENT_ADDRESS,
    GET_ALL_INVOICES,
    GET_ALL_INVOICES_SUCCESS,
    GET_ALL_INVOICES_ERROR,
} from '../actions';

import jwt_decode from 'jwt-decode'

const INIT_STATE = {
    user: setToken(),
    forgotUserMail: '',
    newPassword: '',
    resetPasswordCode: '',
    status: '',
    loading: false,
    reloading: false, // 'Getting new Token'
    message: '',
    error: '',
    requireTwoFactor: false,
    requireSignature: false,
    twoFactorGenerated: {},
    challenge: null,
    paymentGenerated: {},
    paymentGeneratedTis: {},
    invoices: {},
    links: localStorage.getItem('links') ? JSON.parse(localStorage.getItem('links')) : {
      binance: true,
    },
    chart: localStorage.getItem('chart') ? JSON.parse(localStorage.getItem('chart')) : {
      ma7: true,
      ma25: true,
      ma50: false,
      ma99: true,
      ma200: false,
      rsi: false,
      bb: false,
      vwap: false,
      icloud: false,
      aoscillator: false,
      psar: false,
      tripleema: false,
      stochasticrsi: false,
      macd: false,
      volume: true,
      draw: true,
      style: "1",
      interval: "60",
    },
    clocks: localStorage.getItem('clocks') ? JSON.parse(localStorage.getItem('clocks')) : {
        newYork: true,
        london: true,
        beijing: true,
        local: false,
        utc: false,
        format: false,
    },
    alerts: localStorage.getItem('alerts') ? JSON.parse(localStorage.getItem('alerts')) : {
        blockspy: true,
        news: true,
        listings: true,
    },
    images: {},
    plans: {
      1: 0.19,
      6: 0.95,
      12: 1.9,
    },
    plansTis: {
      1: 0.095,
      6: 0.475,
      12: 0.95,
    },
    discordUser: null,
    discordInvite: null,
    favourites: {
      binance: [],
      bittex: [],
      bitfinex: [],
      kucoin: [],
      okex: []
    },
};

function setToken() {
  if(localStorage.getItem('token')) {
    try {
      const decoded = jwt_decode(localStorage.getItem('token'))
      return decoded
    } catch (error) {
      return null
    }
  }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FAVOURITES:
            return { ...state, loading: true, message: '', error: '', status: action.type };
        case GET_FAVOURITES_SUCCESS:
            return { ...state, loading: false, message: '', favourites: action.payload.favourites, status: action.type };
        case GET_FAVOURITES_ERROR:
            return { ...state, loading: false, message: '', error: action.payload.message, status: action.type };
        case TOGGLE_FAVOURITES:
            return { ...state, loading: true, message: '', error: '', status: action.type };
        case TOGGLE_FAVOURITES_SUCCESS:
            return { ...state, loading: false, message: '', favourites: action.payload.favourites, status: action.type };
        case TOGGLE_FAVOURITES_ERROR:
          return { ...state, loading: false, message: '', error: action.payload.message, status: action.type };
        case GET_ALL_INVOICES:
            return { ...state, loading: true, message: '', error: '', status: action.type };
        case GET_ALL_INVOICES_SUCCESS:
            return { ...state, loading: false, message: '', invoices: action.payload.invoices, status: action.type };
        case GET_ALL_INVOICES_ERROR:
          return { ...state, loading: false, message: '', error: action.payload.message, status: action.type };
        case DISCORD_GET_TOKEN:
          return { ...state, loading: true, message: '', error: '', status: action.type };
        case DISCORD_GET_TOKEN_SUCCESS:
          return { ...state, loading: false, message: '', discordUser: action.payload.discordUser, status: action.type };
        case DISCORD_GET_TOKEN_ERROR:
          return { ...state, loading: false, message: '', error: action.payload.message, status: action.type };
        case DISCORD_GET_INVITE:
          return { ...state, loading: true, message: '', error: '', status: action.type };
        case DISCORD_GET_INVITE_SUCCESS:
          return { ...state, loading: false, message: '', discordInvite: action.payload.discordInvite, status: action.type };
        case DISCORD_GET_INVITE_ERROR:
          return { ...state, loading: false, message: '', error: action.payload.message, status: action.type };
        case CLEAR_PAYMENT_ADDRESS:
            return { ...state, loading: false, message: '', paymentGenerated: {}, paymentGeneratedTis: {}, status: action.type };
        case REQUEST_PAYMENT_ADDRESS:
            return { ...state, loading: true, message: '', error: '', status: action.type };
        case REQUEST_PAYMENT_ADDRESS_SUCCESS:
            return { ...state, loading: false, message: '', paymentGenerated: action.payload.paymentGenerated, status: action.type };
        case REQUEST_PAYMENT_ADDRESS_ERROR:
          return { ...state, loading: false, message: '', error: action.payload.message, status: action.type };
        case REQUEST_PAYMENT_ADDRESS_TIS:
          return { ...state, loading: true, message: '', error: '', status: action.type };
        case REQUEST_PAYMENT_ADDRESS_TIS_SUCCESS:
          return { ...state, loading: false, message: '', paymentGeneratedTis: action.payload.paymentGenerated, status: action.type };
        case REQUEST_PAYMENT_ADDRESS_TIS_ERROR:
          return { ...state, loading: false, message: '', error: action.payload.message, status: action.type };
        case GET_COIN_IMAGES:
            return {...state }
        case GET_COIN_IMAGES_SUCCESS:
            return {...state, images: action.payload.images }
        case GET_COIN_IMAGES_ERROR:
            return {...state }
        case GET_PLAN_PRICES:
            return {...state }
        case GET_PLAN_PRICES_SUCCESS:
          return {...state, plans: action.payload.plans }
        case GET_PLAN_PRICES_ERROR:
            return {...state }
        case GET_PLAN_PRICES_TIS:
          return {...state }
        case GET_PLAN_PRICES_TIS_SUCCESS:
          return {...state, plansTis: action.payload.plans }
        case GET_PLAN_PRICES_TIS_ERROR:
            return {...state }
        case SET_CHART:
            return {...state, chart: {...action.payload.chart}, status: action.type };
        case SET_CLOCKS:
            return {...state, clocks: {...action.payload.clocks}, status: action.type };
        case SET_ALERTS:
            return {...state, alerts: {...action.payload.alerts}, status: action.type };
        case SET_LINKS:
            return {...state, links: {...action.payload.links}, status: action.type };
        case CLEAR_MESSAGES:
            return {...state, message: '', error: '', status: action.type };
        case LOGIN_USER:
            return { ...state, loading: true, message: '', error: '', status: action.type };
        case LOGIN_USER_REQUIRE_TWOFACTOR:
            return { ...state, loading: false, message: '', error: '', requireTwoFactor: true, status: action.type };
        case LOGIN_USER_REQUIRE_SIGNATURE:
            return { ...state, loading: false, message: '', error: '', requireSignature: true, challenge: action.payload.challenge, status: action.type };
        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false, message: '', error: '', user: action.payload, status: action.type };
        case LOGIN_USER_METAMASK:
            return { ...state, loading: true, message: '', error: '', requireSignature: false, status: action.type };
        case LOGIN_USER_METAMASK_SUCCESS:
            return { ...state, loading: false, message: '', user: action.payload, error: '', status: action.type };
        case LOGIN_USER_METAMASK_ERROR:
            return { ...state, loading: false, message: '', user: '', error: action.payload.message, status: action.type };
        case LOGIN_USER_ERROR:
            return { ...state, loading: false, message: '', user: '', error: action.payload.message, status: action.type };
        case METAMASK_GENERATE:
            return { ...state, loading: true, message: '', error: '', status: action.type };
        case METAMASK_GENERATE_SUCCESS:
            return { ...state, loading: false, message: '', metaMaskGenerated: action.payload.metaMaskPayload, status: action.type };
        case METAMASK_GENERATE_ERROR:
            return { ...state, loading: false, message: '', error: action.payload.message, status: action.type };
        case METAMASK_ENABLE:
            return { ...state, loading: true, message: '',  error: '', status: action.type };
        case METAMASK_ENABLE_SUCCESS:
            return { ...state, loading: false, message: action.payload.message, status: action.type };
        case METAMASK_ENABLE_ERROR:
            return { ...state, loading: false, message: '', error: action.payload.message, status: action.type };
        case METAMASK_DISABLE:
            return { ...state, loading: true, message: '', error: '', status: action.type};
        case METAMASK_DISABLE_SUCCESS:
            return { ...state, loading: false, message: action.payload.message, status: action.type };
        case METAMASK_DISABLE_ERROR:
            return { ...state, loading: false, message: '', error: action.payload.message, status: action.type};
        case CHANGE_PASSWORD:
            return { ...state, loading: true, message: '', error: '' };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, loading: false, message: action.payload.message, error: '', status: action.type };
        case CHANGE_PASSWORD_ERROR:
            return { ...state, loading: false, message: '', error: action.payload.message, status: action.type };
        case TWO_FACTOR_GENERATE:
            return { ...state, loading: true, message: '', error: '', status: action.type };
        case TWO_FACTOR_GENERATE_SUCCESS:
            return { ...state, loading: false, message: '', twoFactorGenerated: action.payload.twoFactorPayload, status: action.type };
        case TWO_FACTOR_GENERATE_ERROR:
            return { ...state, loading: false, message: '', error: action.payload.message, status: action.type };
        case TWO_FACTOR_ENABLE:
            return { ...state, loading: true, message: '',  error: '', status: action.type };
        case TWO_FACTOR_ENABLE_SUCCESS:
            return { ...state, loading: false, message: '', status: action.type };
        case TWO_FACTOR_ENABLE_ERROR:
            return { ...state, loading: false, message: '', error: action.payload.message, status: action.type };
        case TWO_FACTOR_DISABLE:
            return { ...state, loading: true, message: '',  error: '', status: action.type };
        case TWO_FACTOR_DISABLE_SUCCESS:
            return { ...state, loading: false, message: '', status: action.type };
        case TWO_FACTOR_DISABLE_ERROR:
            return { ...state, loading: false, message: '', error: action.payload.message, status: action.type };
        case FORGOT_PASSWORD:
            return { ...state, loading: true, message: '', error: '', status: action.type };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, error: '', message: action.payload, status: action.type };
        case FORGOT_PASSWORD_ERROR:
            return { ...state, loading: false, message: '', forgotUserMail: '', error: action.payload.message, status: action.type };
        case RESET_PASSWORD:
            return { ...state, loading: true, message: '', error: '', status: action.type };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false, message: action.payload.message, error: '', status: action.type };
        case RESET_PASSWORD_ERROR:
            return { ...state, loading: false, message: '', newPassword: '', resetPasswordCode: '', error: action.payload.message , status: action.type };
        case REGISTER_USER:
            return { ...state, loading: true, message: action.payload.message, error: '', status: action.type };
        case REGISTER_USER_SUCCESS:
            return { ...state, loading: false, message: '', user: action.payload, error: '', status: action.type };
        case REGISTER_USER_ERROR:
            return { ...state, loading: false, message: '', user: '', error: action.payload.message, status: action.type };
        case VERIFY_USER_EMAIL:
            return { ...state, loading: true, message: '', error: '', status: action.type };
        case VERIFY_USER_EMAIL_SUCCESS:
            return { ...state, loading: false, message: action.payload.message, error: '', status: action.type };
        case VERIFY_USER_EMAIL_ERROR:
            return { ...state, loading: false, message: '', error: action.payload, status: action.type  };
        case REFRESH_PROFILE:
            return { ...state, reloading: true, status: action.type };
        case REFRESH_PROFILE_SUCCESS:
            return { ...state, reloading: false, user: action.payload.user, status: action.type };
        case REFRESH_PROFILE_ERROR:
            return { ...state, reloading: false, status: action.type };
        case LOGOUT_USER:
            return { ...state, requireTwoFactor: false, paymentGenerated: {}, requireSignature: false, user: null, error: '', status: action.type };
        default: return { ...state };
    }
}
