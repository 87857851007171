import {
  TOGGLE_FAVOURITES,
  TOGGLE_FAVOURITES_SUCCESS,
  TOGGLE_FAVOURITES_ERROR,
  GET_FAVOURITES,
  GET_FAVOURITES_SUCCESS,
  GET_FAVOURITES_ERROR,
  GET_COIN_IMAGES,
  GET_COIN_IMAGES_SUCCESS,
  GET_COIN_IMAGES_ERROR,
  GET_PLAN_PRICES,
  GET_PLAN_PRICES_SUCCESS,
  GET_PLAN_PRICES_ERROR,
  GET_PLAN_PRICES_TIS,
  GET_PLAN_PRICES_TIS_SUCCESS,
  GET_PLAN_PRICES_TIS_ERROR,
  DISCORD_GET_TOKEN,
  DISCORD_GET_TOKEN_SUCCESS,
  DISCORD_GET_TOKEN_ERROR,
  DISCORD_GET_INVITE,
  DISCORD_GET_INVITE_SUCCESS,
  DISCORD_GET_INVITE_ERROR,
  LOGIN_USER,
  LOGIN_USER_REQUIRE_TWOFACTOR,
  LOGIN_USER_SUCCESS,
  TWO_FACTOR_GENERATE,
  TWO_FACTOR_GENERATE_SUCCESS,
  TWO_FACTOR_GENERATE_ERROR,
  TWO_FACTOR_ENABLE,
  TWO_FACTOR_ENABLE_SUCCESS,
  TWO_FACTOR_ENABLE_ERROR,
  TWO_FACTOR_DISABLE,
  TWO_FACTOR_DISABLE_SUCCESS,
  TWO_FACTOR_DISABLE_ERROR,
  LOGOUT_USER,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  VERIFY_USER_EMAIL,
  VERIFY_USER_EMAIL_SUCCESS,
  VERIFY_USER_EMAIL_ERROR,
  REFRESH_PROFILE,
  REFRESH_PROFILE_SUCCESS,
  REFRESH_PROFILE_ERROR,
  CLEAR_MESSAGES,
  SET_CLOCKS,
  SET_CHART,
  SET_ALERTS,
  LOGIN_USER_REQUIRE_SIGNATURE,
  LOGIN_USER_METAMASK,
  LOGIN_USER_METAMASK_SUCCESS,
  LOGIN_USER_METAMASK_ERROR,
  METAMASK_GENERATE,
  METAMASK_GENERATE_SUCCESS,
  METAMASK_GENERATE_ERROR,
  METAMASK_ENABLE,
  METAMASK_ENABLE_SUCCESS,
  METAMASK_ENABLE_ERROR,
  METAMASK_DISABLE,
  METAMASK_DISABLE_SUCCESS,
  METAMASK_DISABLE_ERROR,
  REQUEST_PAYMENT_ADDRESS,
  REQUEST_PAYMENT_ADDRESS_SUCCESS,
  REQUEST_PAYMENT_ADDRESS_ERROR,
  REQUEST_PAYMENT_ADDRESS_TIS,
  REQUEST_PAYMENT_ADDRESS_TIS_SUCCESS,
  REQUEST_PAYMENT_ADDRESS_TIS_ERROR,
  CLEAR_PAYMENT_ADDRESS,
  GET_ALL_INVOICES,
  GET_ALL_INVOICES_SUCCESS,
  GET_ALL_INVOICES_ERROR,
  SET_LINKS,
} from '../actions';

export const getFavourites = () => ({
  type: GET_FAVOURITES,
  payload: null
})

export const getFavouritesSuccess = (favourites) => ({
  type: GET_FAVOURITES_SUCCESS,
  payload: { favourites }
})

export const getFavouritesError = (message) => ({
  type: GET_FAVOURITES_ERROR,
  payload: { message }
})

export const toggleFavourites = (favourites, exchange, pair) => ({
  type: TOGGLE_FAVOURITES,
  payload: { favourites, exchange, pair }
})

export const toggleFavouritesSuccess = (favourites) => ({
  type: TOGGLE_FAVOURITES_SUCCESS,
  payload: { favourites }
})

export const toggleFavouritesError = (message) => ({
  type: TOGGLE_FAVOURITES_ERROR,
  payload: { message }
})

export const getDiscordInvite = () => ({
  type: DISCORD_GET_INVITE,
  payload: null
})

export const getDiscordInviteSuccess = (discordInvite) => ({
  type: DISCORD_GET_INVITE_SUCCESS,
  payload: { discordInvite }
})

export const getDiscordInviteError = (message) => ({
  type: DISCORD_GET_INVITE_ERROR,
  payload: { message }
})

export const getDiscordToken = () => ({
  type: DISCORD_GET_TOKEN,
  payload: null
})

export const getDiscordTokenSuccess = (discordUser) => ({
  type: DISCORD_GET_TOKEN_SUCCESS,
  payload: { discordUser }
})

export const getDiscordTokenError = (message) => ({
  type: DISCORD_GET_TOKEN_ERROR,
  payload: { message }
})

export const clearPaymentAddress = () => ({
  type: CLEAR_PAYMENT_ADDRESS,
  payload: null
})

export const getAllInvoices = () => ({
  type: GET_ALL_INVOICES,
  payload: null
})
export const clearPaymentAddressSuccess = (invoices) => ({
  type: GET_ALL_INVOICES_SUCCESS,
  payload: { invoices }
})
export const clearPaymentAddressError = (message) => ({
  type: GET_ALL_INVOICES_ERROR,
  payload: { message }
})

export const requestPaymentAddress = (planId) => ({
  type: REQUEST_PAYMENT_ADDRESS,
  payload: { planId }
})
export const requestPaymentAddressSuccess = (paymentGenerated) => ({
  type: REQUEST_PAYMENT_ADDRESS_SUCCESS,
  payload: { paymentGenerated }
})
export const requestPaymentAddressError = (message) => ({
  type: REQUEST_PAYMENT_ADDRESS_ERROR,
  payload: { message }
})

export const requestPaymentAddressTis = (planId) => ({
  type: REQUEST_PAYMENT_ADDRESS_TIS,
  payload: { planId }
})
export const requestPaymentAddressTisSuccess = (paymentGenerated) => ({
  type: REQUEST_PAYMENT_ADDRESS_TIS_SUCCESS,
  payload: { paymentGenerated }
})
export const requestPaymentAddressTisError = (message) => ({
  type: REQUEST_PAYMENT_ADDRESS_TIS_ERROR,
  payload: { message }
})

export const loginUserRequireSignature = (challenge) => ({
  type: LOGIN_USER_REQUIRE_SIGNATURE,
  payload: { challenge }
})
export const getCoinImages = () => ({
  type: GET_COIN_IMAGES,
  payload: null
})
export const getCoinImagesSuccess = (images) => ({
  type: GET_COIN_IMAGES_SUCCESS,
  payload: { images }
})
export const getCoinImagesError = () => ({
  type: GET_COIN_IMAGES_ERROR,
  payload: null
})
export const getPlanPrices = () => ({
  type: GET_PLAN_PRICES,
  payload: null
})
export const getPlanPricesSuccess = (plans) => ({
  type: GET_PLAN_PRICES_SUCCESS,
  payload: { plans }
})
export const getPlanPricesError = () => ({
  type: GET_PLAN_PRICES_ERROR,
  payload: null
})
export const getPlanPricesTis = () => ({
  type: GET_PLAN_PRICES_TIS,
  payload: null
})
export const getPlanPricesTisSuccess = (plans) => ({
  type: GET_PLAN_PRICES_TIS_SUCCESS,
  payload: { plans }
})
export const getPlanPricesTisError = () => ({
  type: GET_PLAN_PRICES_TIS_ERROR,
  payload: null
})
export const setClocks = (clocks) => {
  localStorage.setItem('clocks', JSON.stringify(clocks))
  return ({
    type: SET_CLOCKS,
    payload: { clocks }
  })
}
export const setAlerts = (alerts) => {
  localStorage.setItem('alerts', JSON.stringify(alerts))
  return ({
    type: SET_ALERTS,
    payload: { alerts }
  })
}
export const setLinks = (links) => {
  localStorage.setItem('links', JSON.stringify(links))
  return ({
    type: SET_LINKS,
    payload: { links }
  })
}
export const setChart = (chart) => {
  localStorage.setItem('chart', JSON.stringify(chart))
  return ({
    type: SET_CHART,
    payload: { chart }
  })
}
export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
  payload: null
})
export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});
export const refreshProfile = () => ({
    type: REFRESH_PROFILE,
    payload: null
});
export const refreshProfileSuccess = (user) => ({
    type: REFRESH_PROFILE_SUCCESS,
    payload: { user }
});
export const refreshProfileError = () => ({
    type: REFRESH_PROFILE_ERROR,
    payload: null
});
export const loginUserTwoFactor = (require2FA) => ({
  type: LOGIN_USER_REQUIRE_TWOFACTOR,
  payload: require2FA
})
export const loginUserMetamask = (user, history) => ({
  type: LOGIN_USER_METAMASK,
  payload: { user, history }
});
export const loginUserMetamaskSuccess = (user) => ({
  type: LOGIN_USER_METAMASK_SUCCESS,
  payload: user
});
export const loginUserMetamaskError = (message) => ({
  type: LOGIN_USER_METAMASK_ERROR,
  payload: { message }
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});
export const changePassword = (user) => ({
  type: CHANGE_PASSWORD,
  payload: {user}
});
export const changePasswordSuccess = (message) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: { message}
});
export const changePasswordError = (message) => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: { message}
});
export const verifyUserEmail = (user) => ({
  type: VERIFY_USER_EMAIL,
  payload: { user }
});
export const verifyUserEmailSuccess = (message) => ({
  type: VERIFY_USER_EMAIL_SUCCESS,
  payload: { message }
});

export const verifyUserEmailError = (user) => ({
  type: VERIFY_USER_EMAIL_ERROR,
  payload: user
});

export const metaMaskGenerate = (user) => ({
  type: METAMASK_GENERATE,
  payload: { user }
});
export const metaMaskGenerateSuccess = (metaMaskPayload) => ({
  type: METAMASK_GENERATE_SUCCESS,
  payload: { metaMaskPayload }
});
export const metaMaskGenerateError = (message) => ({
  type: METAMASK_GENERATE_ERROR,
  payload: { message }
});
export const metaMaskEnable = (publicKey) => ({
  type: METAMASK_ENABLE,
  payload: { publicKey }
});
export const metaMaskEnableSuccess = (message) => ({
  type: METAMASK_ENABLE_SUCCESS,
  payload: { message }
});
export const metaMaskEnableError = (message) => ({
  type: METAMASK_ENABLE_ERROR,
  payload: { message }
});
export const metaMaskDisable = (user, metaMaskPayload) => ({
  type: METAMASK_DISABLE,
  payload: { user, metaMaskPayload }
});
export const metaMaskDisableSuccess = (message) => ({
  type: METAMASK_DISABLE_SUCCESS,
  payload: { message }
});
export const metaMaskDisableError = (message) => ({
  type: METAMASK_DISABLE_ERROR,
  payload: { message }
});

export const twoFactorGenerate = (user) => ({
  type: TWO_FACTOR_GENERATE,
  payload: { user }
});
export const twoFactorGenerateSuccess = (twoFactorPayload) => ({
  type: TWO_FACTOR_GENERATE_SUCCESS,
  payload: { twoFactorPayload }
});
export const twoFactorGenerateError = (message) => ({
  type: TWO_FACTOR_GENERATE_ERROR,
  payload: { message }
});
export const twoFactorEnable = (user, twoFactor) => ({
  type: TWO_FACTOR_ENABLE,
  payload: { user, twoFactor }
});
export const twoFactorEnableSuccess = () => ({
  type: TWO_FACTOR_ENABLE_SUCCESS,
  payload: null
});
export const twoFactorEnableError = (message) => ({
  type: TWO_FACTOR_ENABLE_ERROR,
  payload: { message }
});
export const twoFactorDisable = (user, twoFactor) => ({
  type: TWO_FACTOR_DISABLE,
  payload: { user, twoFactor }
});
export const twoFactorDisableSuccess = () => ({
  type: TWO_FACTOR_DISABLE_SUCCESS,
  payload: null
});
export const twoFactorDisableError = (message) => ({
  type: TWO_FACTOR_DISABLE_ERROR,
  payload: { message }
});
export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history }
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message }
});
export const resetPassword = (user) => ({
  type: RESET_PASSWORD,
  payload: { user }
});
export const resetPasswordSuccess = (message) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: { message }
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message }
});
export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history }
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
})

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history }
});
