import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    GET_FAVOURITES,
    TOGGLE_FAVOURITES,
    LOGIN_USER,
    TWO_FACTOR_GENERATE,
    TWO_FACTOR_ENABLE,
    TWO_FACTOR_DISABLE,
    REGISTER_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
    VERIFY_USER_EMAIL,
    REFRESH_PROFILE,
    GET_COIN_IMAGES,
    GET_PLAN_PRICES,
    GET_PLAN_PRICES_TIS,
    LOGIN_USER_METAMASK,
    METAMASK_ENABLE,
    METAMASK_DISABLE,
    METAMASK_GENERATE,
    REQUEST_PAYMENT_ADDRESS,
    REQUEST_PAYMENT_ADDRESS_TIS,
    GET_ALL_INVOICES,
} from '../actions';

import {
    getFavouritesSuccess,
    getFavouritesError,
    toggleFavouritesSuccess,
    toggleFavouritesError,
    clearPaymentAddressSuccess,
    clearPaymentAddressError,
    requestPaymentAddressSuccess,
    requestPaymentAddressError,
    requestPaymentAddressTisSuccess,
    requestPaymentAddressTisError,
    metaMaskGenerateSuccess,
    metaMaskGenerateError,
    metaMaskEnableSuccess,
    metaMaskEnableError,
    metaMaskDisableSuccess,
    metaMaskDisableError,
    loginUserRequireSignature,
    getCoinImagesSuccess,
    getCoinImagesError,
    getPlanPricesSuccess,
    getPlanPricesError,
    getPlanPricesTisSuccess,
    getPlanPricesTisError,
    loginUserTwoFactor,
    loginUserSuccess,
    loginUserError,
    changePasswordSuccess,
    changePasswordError,
    twoFactorGenerateSuccess,
    twoFactorGenerateError,
    twoFactorEnableSuccess,
    twoFactorEnableError,
    twoFactorDisableSuccess,
    twoFactorDisableError,
    registerUserSuccess,
    registerUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError,
    verifyUserEmailSuccess,
    verifyUserEmailError,
    refreshProfileSuccess,
    refreshProfileError,
} from './actions';

import api from '../../components/API';
import jwt_decode from 'jwt-decode'

export function* watchGetFavourites() {
    yield takeEvery(GET_FAVOURITES, getFavourites);
}

const getFavouritesAsync = async () => {
    return await api.get(`/users/favourites`,
        {'headers': {'token': localStorage.getItem('token')}}
    )
        .then(response => response)
        .catch(error => error);
};

function* getFavourites({ payload }) {
  try {
    const response = yield call(getFavouritesAsync);
    if (response.data.success) {
      yield put(getFavouritesSuccess(response.data.data))
    } else {
      yield put(getFavouritesError(response.data.message));
    }
  } catch (error) {
    yield put(getFavouritesError(error));
  }
}

export function* watchToggleFavourites() {
    yield takeEvery(TOGGLE_FAVOURITES, toggleFavourites);
}

const toggleFavouritesAsync = async (favourites) => {
    return await api.post('/users/favourites', { favourites },
        {'headers': {'token': localStorage.getItem('token')}}
    )
        .then(response => response)
        .catch(error => error);
};

function* toggleFavourites({ payload }) {
  try {
    const { favourites, exchange, pair } = payload
    if(favourites[exchange].includes(pair)) {
      favourites[exchange] = favourites[exchange].filter(e => e !== pair)
    } else {
      favourites[exchange].push(pair)
    }
    const response = yield call(toggleFavouritesAsync, favourites);
    yield put(toggleFavouritesSuccess(response.data.data))
  } catch (error) {
    console.log(error)
    yield put(toggleFavouritesError(error));
  }
}

export function* watchGetAllInvoices() {
    yield takeEvery(GET_ALL_INVOICES, getAllInvoices);
}

const getAllInvoicesAsync = async () => {
    return await api.get(`/users/invoices`,
        {'headers': {'token': localStorage.getItem('token')}}
    )
        .then(response => response)
        .catch(error => error);
};

function* getAllInvoices({ payload }) {
  try {
    const response = yield call(getAllInvoicesAsync);
    if (response.data.success) {
      yield put(clearPaymentAddressSuccess(response.data.data))
    } else {
      yield put(clearPaymentAddressError(response.data.message));
    }
  } catch (error) {
    yield put(clearPaymentAddressError(error));
  }
}

export function* watchRequestPaymentAddress() {
    yield takeEvery(REQUEST_PAYMENT_ADDRESS, requestPaymentAddress);
}

const requestPaymentAddressAsync = async (planId) => {
    return await api.get(`/users/payment/${planId}`,
        {'headers': {'token': localStorage.getItem('token')}}
    )
        .then(paymentPayload => paymentPayload)
        .catch(error => error);
};

function* requestPaymentAddress({ payload }) {
  try {
    const { planId } = payload
    if(!planId) yield put(requestPaymentAddressError('Plan ID not selected'))
    const response = yield call(requestPaymentAddressAsync, planId);
    if (response.data.success) {
      yield put(requestPaymentAddressSuccess(response.data.data))
    } else {
      yield put(requestPaymentAddressError(response.data.message));
    }
  } catch (error) {
    yield put(requestPaymentAddressError(error));
  }
}


export function* watchRequestPaymentAddressTis() {
    yield takeEvery(REQUEST_PAYMENT_ADDRESS_TIS, requestPaymentAddressTis);
}

const requestPaymentAddressTisAsync = async (planId) => {
    return await api.get(`/users/paymentTis/${planId}`,
        {'headers': {'token': localStorage.getItem('token')}}
    )
        .then(paymentPayload => paymentPayload)
        .catch(error => error);
};

function* requestPaymentAddressTis({ payload }) {
  try {
    console.log(payload)
    const { planId } = payload
    if(!planId) yield put(requestPaymentAddressTisError('Plan ID not selected'))
    const response = yield call(requestPaymentAddressTisAsync, planId);
    if (response.data.success) {
      yield put(requestPaymentAddressTisSuccess(response.data.data))
    } else {
      yield put(requestPaymentAddressTisError(response.data.message));
    }
  } catch (error) {
    yield put(requestPaymentAddressTisError(error));
  }
}

export function* watchGetCoinImages() {
    yield takeEvery(GET_COIN_IMAGES, getCoinImages);
}

const getCoinImagesAsync = async () =>
    await api.get('/api/images', {'headers': {'token': localStorage.getItem('token')}})
      .then(authUser => authUser)
      .catch(error => error);

function* getCoinImages() {
    try {
        const response = yield call(getCoinImagesAsync);
        if (response.data.success) {
            yield put(getCoinImagesSuccess(response.data.data));
        } else {
            yield put(getCoinImagesError(response.data.message));
        }
    } catch (error) {
        yield put(getCoinImagesError(error));

    }
}

export function* watchGetPlanPrices() {
    yield takeEvery(GET_PLAN_PRICES, getPlanPrices);
}

const getPlanPricesAsync = async () =>
    await api.get('/users/plans', {'headers': {'token': localStorage.getItem('token')}})
      .then(authUser => authUser)
      .catch(error => error);

function* getPlanPrices() {
    try {
        const response = yield call(getPlanPricesAsync);
        if (response.data.success) {
            yield put(getPlanPricesSuccess(response.data.data));
        } else {
            yield put(getPlanPricesError(response.data.message));
        }
    } catch (error) {
        yield put(getPlanPricesError(error));

    }
}

export function* watchGetPlanPricesTis() {
    yield takeEvery(GET_PLAN_PRICES_TIS, getPlanPricesTis);
}

const getPlanPricesTisAsync = async () =>
    await api.get('/users/plansTis', {'headers': {'token': localStorage.getItem('token')}})
      .then(authUser => authUser)
      .catch(error => error);

function* getPlanPricesTis() {
    try {
        const response = yield call(getPlanPricesTisAsync);
        if (response.data.success) {
            yield put(getPlanPricesTisSuccess(response.data.data));
        } else {
            yield put(getPlanPricesTisError(response.data.message));
        }
    } catch (error) {
        yield put(getPlanPricesTisError(error));

    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password, twoFactor) =>
    await api.post('/users/login', ({email, password, twoFactor}))
      .then(authUser => authUser)
      .catch(error => error);

function* loginWithEmailPassword({ payload }) {
    const { email, password, twoFactor } = payload.user;
    const { history } = payload;
    try {
        const response = yield call(loginWithEmailPasswordAsync, email, password, twoFactor);
        if (response.data.success) {
          if(response.data.data.require2FA) {
            yield put(loginUserTwoFactor());
          } else {
            localStorage.setItem('token', response.data.data.token);
            yield put(loginUserSuccess(jwt_decode(response.data.data.token)));
            history.push('/app');
          }
        } else {
            yield put(loginUserError(response.data.message));
        }
    } catch (error) {
        yield put(loginUserError(error));

    }
}

export function* watchLoginUserMetamask() {
    yield takeEvery(LOGIN_USER_METAMASK, loginWithMetamask);
}

const loginWithMetamaskAsync = async (publicKey, challenge, signature) =>
    await api.post('/users/login2', ({publicKey, challenge, signature}))
      .then(authUser => authUser)
      .catch(error => error);

function* loginWithMetamask({ payload }) {
    const { publicKey, challenge, signature } = payload.user;
    const { history } = payload;
    try {
        const response = yield call(loginWithMetamaskAsync, publicKey, challenge, signature);
        if (response.data.success) {
          if(response.data.data.requireSig) {
            yield put(loginUserRequireSignature(response.data.data.challenge));
          } else {
            localStorage.setItem('token', response.data.data.token);
            yield put(loginUserSuccess(jwt_decode(response.data.data.token)));
            history.push('/app');
          }
        } else {
            yield put(loginUserError(response.data.message));
        }
    } catch (error) {
        yield put(loginUserError(error));
    }
}

export function* watchChangePassword() {
    yield takeEvery(CHANGE_PASSWORD, changePassword);
}

const changePasswordAsync = async (oldPassword, password, passwordConfirm) =>
    await api.put('/users/update', ({oldPassword, password, passwordConfirm}))
      .then(authUser => authUser)
      .catch(error => error);

function* changePassword({ payload }) {
    const { oldPassword, password, passwordConfirm } = payload.user;
    try {
        const response = yield call(changePasswordAsync, oldPassword, password, passwordConfirm);
        if (response.data.success) {
            yield put(changePasswordSuccess(response.data.message));
        } else {
            yield put(changePasswordError(response.data.message));
        }
    } catch (error) {
        yield put(changePasswordError(error));
    }
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (username, email, password, passwordConfirm, affiliate) =>
    await api.post('/users/register', ({username, email, password, passwordConfirm, affiliate}))
      .then(authUser => authUser)
      .catch(error => error);

function* registerWithEmailPassword({ payload }) {
    const { username, email, password, passwordConfirm } = payload.user;
    let { affiliate } = payload.user;
    if (affiliate.length && affiliate.includes('referer=')) {
      affiliate = affiliate.split('referer=')[1]
    }
    try {
        const response = yield call(registerWithEmailPasswordAsync, username, email, password, passwordConfirm, affiliate);
        if (response.data.success) {
          yield put(registerUserSuccess(response.data.message))
        } else {
          yield put(registerUserError(response.data.message));
        }

    } catch (error) {
        yield put(registerUserError(error));
    }
}


export function* watchTwoFactorGenerate() {
    yield takeEvery(TWO_FACTOR_GENERATE, generateTwoFactor);
}

const generateTwoFactorAsync = async (authStr) => {
    return await api.get(`/users/twoFactor`,
        {'headers': {'token': localStorage.getItem('token')}}
    )
        .then(twoFactorPayload => twoFactorPayload)
        .catch(error => error);
};

function* generateTwoFactor({ payload }) {
  try {
    const response = yield call(generateTwoFactorAsync);
    if(!response.data.data.secret.base32) yield put(twoFactorGenerateError('Missing data from endpoint')); // this will throw an error to catch if expected not returned
    yield put(twoFactorGenerateSuccess(response.data.data))
  } catch (error) {
    yield put(twoFactorGenerateError(error));
  }
}

export function* watchMetamaskGenerate() {
    yield takeEvery(METAMASK_GENERATE, generateMetamask);
}

const generateMetamaskAsync = async (authStr) => {
    return await api.get(`/users/publicKey`,
        {'headers': {'token': localStorage.getItem('token')}}
    )
        .then(metaMaskPayload => metaMaskPayload)
        .catch(error => error);
};

function* generateMetamask({ payload }) {
  try {
    const response = yield call(generateMetamaskAsync);
    if(response.data.success) {
      yield put(metaMaskGenerateSuccess(response.data.data))
    } else {
      yield put(metaMaskGenerateError(response.data.message));
    }
  } catch (error) {
    yield put(metaMaskGenerateError(error));
  }
}

export function* watchMetamaskEnable() {
    yield takeEvery(METAMASK_ENABLE, enableMetamask);
}

const enableMetamaskAsync = async (publicKey) => {
    return await api.post(`/users/publicKey`, {publicKey},
        {
          'headers': {
            'token': localStorage.getItem('token')
          }
        }
      )
        .then(twoFactorPayload => twoFactorPayload)
        .catch(error => error);
};

function* enableMetamask({ payload }) {
  const { publicKey } = payload;
  try {
    const response = yield call(enableMetamaskAsync, publicKey);
    if(response.data.success) {
      yield put(metaMaskEnableSuccess(response.data.message));
    } else {
      yield put(metaMaskEnableError(response.data.message));
    }
  } catch (error) {
      yield put(metaMaskEnableError(error));
  }
}

export function* watchMetamaskDisable() {
  yield takeEvery(METAMASK_DISABLE, disableMetamask);
}

const disableMetamaskAsync = async () => {
    return await api.delete('/users/publicKey', {
          headers: {
            'token': localStorage.getItem('token')
          }
        })
        .then(metaMaskPayload => metaMaskPayload)
        .catch(error => error);
};

function* disableMetamask({ payload }) {
  try {
    const response = yield call(disableMetamaskAsync);
    if(response.data.success) {
      yield put(metaMaskDisableSuccess(response.data.message));
    } else {
      yield put(metaMaskDisableError(response.data.message));
    }
  } catch (error) {
      yield put(metaMaskDisableError(error));
  }
}
//

export function* watchTwoFactorEnable() {
    yield takeEvery(TWO_FACTOR_ENABLE, enableTwoFactor);
}

const enableTwoFactorAsync = async (twoFactor) => {
    return await api.post(`/users/twoFactor`, {twoFactor},
        {
          'headers': {
            'token': localStorage.getItem('token')
          }
        }
      )
        .then(twoFactorPayload => twoFactorPayload)
        .catch(error => error);
};

function* enableTwoFactor({ payload }) {
  const { twoFactor } = payload;
  try {
    const response = yield call(enableTwoFactorAsync, twoFactor);
    if(response.data.success) {
      yield put(twoFactorEnableSuccess());
    } else {
      yield put(twoFactorEnableError(response.data.message));
    }
  } catch (error) {
      yield put(twoFactorEnableError(error));
  }
}

export function* watchTwoFactorDisable() {
  yield takeEvery(TWO_FACTOR_DISABLE, disableTwoFactor);
}

const disableTwoFactorAsync = async (twoFactor) => {
    return await api.delete('/users/twoFactor', {
          headers: {
            'token': localStorage.getItem('token')
          },
          data: {
            twoFactor
          }
        })
        .then(twoFactorPayload => twoFactorPayload)
        .catch(error => error);
};

function* disableTwoFactor({ payload }) {
  const { twoFactor } = payload;
  try {
    const response = yield call(disableTwoFactorAsync, twoFactor);
    if(response.data.success) {
      yield put(twoFactorDisableSuccess());
    } else {
      yield put(twoFactorDisableError(response.data.message));
    }
  } catch (error) {
      yield put(twoFactorDisableError(error));
  }
}

export function* watchRefreshProfile() {
  yield takeEvery(REFRESH_PROFILE, refreshProfile);
}

const refreshProfileAsync = async () => {
      return await api.post(`/users/refreshToken`, {}, {
        headers: {
          token: localStorage.getItem('token')
        }
      })
      .then(authUser => authUser)
      .catch(error => error);
};

function* refreshProfile({ payload }) {
  try {
    const response = yield call(refreshProfileAsync);
    if(response.data.success) {
      localStorage.setItem('token', response.data.data.token)
      yield put(refreshProfileSuccess(jwt_decode(response.data.data.token)));
    } else {
      yield put(refreshProfileError(response.data.message));
    }
  } catch (error) {
      yield put(refreshProfileError(error));
  }
}


export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    history.push('/')
}

function* logout({ payload }) {
    const { history } = payload
    try {
      localStorage.removeItem('token');
        yield call(logoutAsync, history);
    } catch (error) {
    }
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
    return await api.post(`/users/forgot`, {email})
        .then(user => user)
        .catch(error => error);
}

function* forgotPassword({ payload }) {
    const { forgotUserMail } = payload;
    try {
      const response = yield call(forgotPasswordAsync, forgotUserMail);
      if (response.data.success) {
          yield put(forgotPasswordSuccess(response.data.message));
      } else {
          yield put(forgotPasswordError(response.data.message));
      }
    } catch (error) {
        yield put(forgotPasswordError(error));
    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (email, resetToken, password, passwordConfirm) => {
    return await api.post(`/users/reset`, {email, resetToken, password, passwordConfirm})
        .then(user => user)
        .catch(error => error);
}

function* resetPassword({ payload }) {
    const { email, resetToken, password, passwordConfirm } = payload.user;
    try {
      const response = yield call(resetPasswordAsync, email, resetToken, password, passwordConfirm);
      if(response.data.success) {
        yield put(resetPasswordSuccess(response.data.message))
      } else {
        yield put(resetPasswordError(response.data.message));
      }
    } catch (error) {
        yield put(resetPasswordError(error));

    }
}

export function* watchVerifyUserEmail() {
    yield takeEvery(VERIFY_USER_EMAIL, verifyUserEmail);
}

const verifyUserEmailAsync = async (email, emailVerifiedToken) => {
    return await api.post(`/users/verify`, {email, emailVerifiedToken})
        .then(user => user)
        .catch(error => error);
}

function* verifyUserEmail({ payload }) {
    const { email, emailVerifiedToken } = payload.user;
    try {
        const response = yield call(verifyUserEmailAsync, email, emailVerifiedToken);
        if(response.data.success) {
          yield put(verifyUserEmailSuccess(response.data.message))
        } else {
          yield put(verifyUserEmailError(response.data.message));
        }
    } catch (error) {
        yield put(verifyUserEmailError(error));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchGetFavourites),
        fork(watchToggleFavourites),
        fork(watchGetAllInvoices),
        fork(watchRequestPaymentAddress),
        fork(watchRequestPaymentAddressTis),
        fork(watchMetamaskEnable),
        fork(watchMetamaskDisable),
        fork(watchLoginUserMetamask),
        fork(watchGetCoinImages),
        fork(watchGetPlanPrices),
        fork(watchGetPlanPricesTis),
        fork(watchVerifyUserEmail),
        fork(watchRefreshProfile),
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchChangePassword),
        fork(watchRegisterUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
        fork(watchTwoFactorGenerate),
        fork(watchTwoFactorEnable),
        fork(watchTwoFactorDisable),
    ]);
}
