/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/

export const isTestMode = false;

let backend, backendTA;

if(isTestMode) {
  backend = 'http://localhost:9999';
  backendTA = 'http://localhost:9998';
} else {
  backend = document.location.origin
  backendTA = document.location.origin
}
export const defaultMenuType = "menu-sub-hidden";

export const baseURL = backend;
export const baseURL2 = backendTA;

export const version = '2.10 beta'
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English", direction: "ltr" },
];

export const planIds = {
  "1": "1 Month",
  "6": "6 Months",
  "12": "12 Months",
}

export const isMultiColorActive = false;
export const defaultColor = "dark.blue";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = false;
export const themeColorStorageKey="__theme_color";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;
