/* FAVOURITES */
export const GET_FAVOURITES = "GET_FAVOURITES";
export const GET_FAVOURITES_SUCCESS = "GET_FAVOURITES_SUCCESS";
export const GET_FAVOURITES_ERROR = "GET_FAVOURITES_ERROR";

export const TOGGLE_FAVOURITES = "TOGGLE_FAVOURITES";
export const TOGGLE_FAVOURITES_SUCCESS = "TOGGLE_FAVOURITES_SUCCESS";
export const TOGGLE_FAVOURITES_ERROR = "TOGGLE_FAVOURITES_ERROR";

/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const GET_COIN_IMAGES = "GET_COIN_IMAGES";
export const GET_COIN_IMAGES_SUCCESS = "GET_COIN_IMAGES_SUCCESS";
export const GET_COIN_IMAGES_ERROR = "GET_COIN_IMAGES_ERROR";
export const GET_PLAN_PRICES = "GET_PLAN_PRICES";
export const GET_PLAN_PRICES_SUCCESS = "GET_PLAN_PRICES_SUCCESS";
export const GET_PLAN_PRICES_ERROR = "GET_PLAN_PRICES_ERROR";
export const GET_PLAN_PRICES_TIS = "GET_PLAN_PRICES_TIS";
export const GET_PLAN_PRICES_TIS_SUCCESS = "GET_PLAN_PRICES_TIS_SUCCESS";
export const GET_PLAN_PRICES_TIS_ERROR = "GET_PLAN_PRICES_TIS_ERROR";
/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGIN_USER_METAMASK = "LOGIN_USER_METAMASK";
export const LOGIN_USER_METAMASK_SUCCESS = "LOGIN_USER_METAMASK_SUCCESS";
export const LOGIN_USER_METAMASK_ERROR = "LOGIN_USER_METAMASK_ERROR";
export const LOGIN_USER_REQUIRE_SIGNATURE = "LOGIN_USER_REQUIRE_SIGNATURE";
export const LOGIN_USER_REQUIRE_TWOFACTOR = "LOGIN_USER_REQUIRE_TWOFACTOR";

export const METAMASK_GENERATE = 'METAMASK_GENERATE';
export const METAMASK_GENERATE_SUCCESS = 'METAMASK_GENERATE_SUCCESS';
export const METAMASK_GENERATE_ERROR = 'METAMASK_GENERATE_ERROR';
export const METAMASK_ENABLE = 'METAMASK_ENABLE';
export const METAMASK_ENABLE_SUCCESS = 'METAMASK_ENABLE_SUCCESS';
export const METAMASK_ENABLE_ERROR = 'METAMASK_ENABLE_ERROR';
export const METAMASK_DISABLE = 'METAMASK_DISABLE';
export const METAMASK_DISABLE_SUCCESS = 'METAMASK_DISABLE_SUCCESS';
export const METAMASK_DISABLE_ERROR = 'METAMASK_DISABLE_ERROR';

export const REQUEST_PAYMENT_ADDRESS = "REQUEST_PAYMENT_ADDRESS"
export const REQUEST_PAYMENT_ADDRESS_SUCCESS = "REQUEST_PAYMENT_ADDRESS_SUCCESS"
export const REQUEST_PAYMENT_ADDRESS_ERROR = "REQUEST_PAYMENT_ADDRESS_ERROR"
export const REQUEST_PAYMENT_ADDRESS_TIS = "REQUEST_PAYMENT_ADDRESS_TIS"
export const REQUEST_PAYMENT_ADDRESS_TIS_SUCCESS = "REQUEST_PAYMENT_ADDRESS_TIS_SUCCESS"
export const REQUEST_PAYMENT_ADDRESS_TIS_ERROR = "REQUEST_PAYMENT_ADDRESS_TIS_ERROR"
export const CLEAR_PAYMENT_ADDRESS = "REQUEST_PAYMENT_ADDRESS"
export const GET_ALL_INVOICES = "GET_ALL_INVOICES";
export const GET_ALL_INVOICES_SUCCESS = "GET_ALL_INVOICES_SUCCESS";
export const GET_ALL_INVOICES_ERROR = "GET_ALL_INVOICES_ERROR";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const TWO_FACTOR_GENERATE = 'TWO_FACTOR_GENERATE';
export const TWO_FACTOR_GENERATE_SUCCESS = 'TWO_FACTOR_GENERATE_SUCCESS';
export const TWO_FACTOR_GENERATE_ERROR = 'TWO_FACTOR_GENERATE_ERROR';

export const DISCORD_GET_TOKEN = 'DISCORD_GET_TOKEN';
export const DISCORD_GET_TOKEN_SUCCESS = 'DISCORD_GET_TOKEN_SUCCESS';
export const DISCORD_GET_TOKEN_ERROR = 'DISCORD_GET_TOKEN_ERROR';

export const DISCORD_GET_INVITE = 'DISCORD_GET_INVITE';
export const DISCORD_GET_INVITE_SUCCESS = 'DISCORD_GET_INVITE_SUCCESS';
export const DISCORD_GET_INVITE_ERROR = 'DISCORD_GET_INVITE_ERROR';

export const TWO_FACTOR_ENABLE = 'TWO_FACTOR_ENABLE';
export const TWO_FACTOR_ENABLE_SUCCESS = 'TWO_FACTOR_ENABLE_SUCCESS';
export const TWO_FACTOR_ENABLE_ERROR = 'TWO_FACTOR_ENABLE_ERROR';
export const TWO_FACTOR_DISABLE = 'TWO_FACTOR_DISABLE';
export const TWO_FACTOR_DISABLE_SUCCESS = 'TWO_FACTOR_DISABLE_SUCCESS';
export const TWO_FACTOR_DISABLE_ERROR = 'TWO_FACTOR_DISABLE_ERROR';

export const REFRESH_PROFILE = "REFRESH_PROFILE";
export const REFRESH_PROFILE_SUCCESS = "REFRESH_PROFILE_SUCCESS";
export const REFRESH_PROFILE_ERROR = "REFRESH_PROFILE_ERROR";

export const VERIFY_USER_EMAIL = "VERIFY_USER_EMAIL";
export const VERIFY_USER_EMAIL_SUCCESS = "VERIFY_USER_EMAIL_SUCCESS";
export const VERIFY_USER_EMAIL_ERROR = "VERIFY_USER_EMAIL_ERROR";

export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const SET_CLOCKS = 'SET_CLOCKS';
export const SET_ALERTS = 'SET_ALERTS';
export const SET_LINKS = 'SET_LINKS';
export const SET_CHART = 'SET_CHART';
/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
