import { baseURL } from '../../constants/defaultValues';

import axios from 'axios';

import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

const defaultOptions = {
    baseURL,
    headers: {
      token: localStorage.getItem('token'),
    },
};

const instance = axios.create(defaultOptions);

instance.interceptors.request.use((config) => {
  config.headers.token = localStorage.getItem('token')
  return config;
})

instance.interceptors.response.use( (response) => {
   return response;
}, (error) => {
    if(!error.response) return;
    console.log(`API2 error code: ${error.response.status}`)
    switch (error.response.status) {
        case 401:
            history.replace('/app/user/logout')
            history.go()
            return error.response;
            break;
        case 402:
            return error.response;
            break;
        default:
            return error.response;
            break;
   }
   return Promise.reject(error);
});

export default instance
